import React, { useState, useEffect } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import config from "../Utils/config"; // Assuming you have a config file for API base URL

// Register chart.js components
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function ContractPerTeam({ setTopTeams }) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch teams
    const fetchTeams = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${config.apiBaseUrl}ContractTimeTeamInfoes/getContractTeamsData`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        console.log("total week data", data)
        setData(data);
      } catch (error) {
        setError("Error fetching teams: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeams();
  }, []);

  // Function to get the start and end date of the current week (Monday to Sunday)
  const getCurrentWeekDates = () => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const startOfWeek = new Date(
      today.setDate(today.getDate() - dayOfWeek + 1)
    ); 
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); 
    return { start: startOfWeek, end: endOfWeek };
  };

  useEffect(() => {
    if (data.length > 0) {
      // Get the start and end date for the current week
      const { start, end } = getCurrentWeekDates();
      
      const startOfWeek = new Date(start);
      startOfWeek.setHours(0, 0, 0, 0);
  
      const endOfWeek = new Date(end);
      endOfWeek.setHours(23, 59, 59, 999); // Include full last day
  
      console.log("Start of Week:", startOfWeek, "End of Week:", endOfWeek);
  
      // Filter the data to include only contracts from the current week
      const filteredData = data.filter((item) => {
        const contractDate = new Date(item.date);
        contractDate.setHours(0, 0, 0, 0); // Normalize date (no time)
  
        return contractDate >= startOfWeek && contractDate <= endOfWeek;
      });
  
      const teamCount = filteredData.reduce((acc, item) => {
        if (item.teamNo) {
          acc[item.teamNo] = (acc[item.teamNo] || 0) + 1;
        }
        return acc;
      }, {});
  
      // Prepare chart data for all teams (all-time data)
      const allTimeTeamCount = data.reduce((acc, item) => {
        if (item.teamNo) {
          acc[item.teamNo] = (acc[item.teamNo] || 0) + 1;
        }
        return acc;
      }, {});
      const chartLabels = Object.keys(allTimeTeamCount);
      const chartValues = Object.values(allTimeTeamCount);
  
      // Set chart data for all-time counts
      setChartData({
        labels: chartLabels,
        datasets: [
          {
            label: "Team Contract Count (All Time)",
            data: chartValues,
            backgroundColor: "rgba(75,192,192,0.4)",
            borderColor: "rgba(75,192,192,1)",
            borderWidth: 1,
          },
        ],
      });
  
      if (Object.keys(teamCount).length === 0 && filteredData.length > 0) {
        const firstTeam = filteredData[0].teamNo;
        teamCount[firstTeam] = 1;
      }
  
      const sortedTeams = Object.entries(teamCount).sort(
        ([, countA], [, countB]) => countB - countA
      );
  
      const topTeamNosWithCounts =
        sortedTeams.length > 0
          ? sortedTeams.slice(0, 3).map(([teamNo, count]) => ({ teamNo, count }))
          : [{ teamNo: "No Data", count: 0 }];
  
      console.log("Top Teams:", topTeamNosWithCounts);
      setTopTeams(topTeamNosWithCounts);
    }
  }, [data, setTopTeams]);
  
  

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;

  return (
    <>
  <div style={{ margin: "auto" }}>
  <h2>Contracts Per Team</h2>
  {chartData && chartData.datasets && chartData.datasets.length > 0 && chartData.datasets[0].data.length > 0 ? (
    <Bar
      data={chartData}
      options={{
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: "Contract Count per Team (All Time)",
          },
        },
        scales: {
          x: {
            beginAtZero: true,
          },
        },
      }}
    />
  ) : (
    <h5 style={{textAlign:'center', color:'grey'}}>No data available</h5>
  )}
</div>

    </>
  );
}

export default ContractPerTeam;
