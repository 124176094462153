import React, { useState, useEffect } from "react";
import { Bar, Pie, Line } from "react-chartjs-2";
import config from "./Utils/config";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
} from "chart.js";
import {
  MilitaryTech,
} from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import ContractGraph from "./Dashboard-Charts/ContractGraph";
import TeamGraph from "./Dashboard-Charts/TeamGraph";
import TodayBarChart from "./Dashboard-Charts/TodayBarChart";
import UserTotalContract from "./Dashboard-Charts/UserTotalContract";
import BalanceLineChart from "./Dashboard-Charts/BalanceLineChart";
import ContractPerTeam from "./Dashboard-Charts/ContractPerTeam";
import ContractStatus from "./Dashboard-Charts/ContractStatus";
import AdminBranch from "./Dashboard-Charts/AdminBranch";


// Register required components for Chart.js
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  ArcElement,
  PointElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [userRole, setUserRole] = useState(""); // For storing the role
  const navigate = useNavigate();
  const [topTeams, setTopTeams] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [allCount, setAllCount] = useState(0);
  const [topBranches, setTopBranches] = useState([]);
  const [contractCount, setContractCount] = useState({
    Approved: 0,
    Confirmed: 0,
    Booked: 0,
    Quoted: 0,
    Cancelled: 0,
  });
  useEffect(() => {
    // Fetch the user's role (assuming it's saved in localStorage)
    const role = localStorage.getItem("role");
    setUserRole(role);
  }, []);

  const handleContractCountUpdate = (newContractCount) => {
    // Only update the state if the new contractCount is different
    if (JSON.stringify(newContractCount) !== JSON.stringify(contractCount)) {
      setContractCount(newContractCount);
    }
  };

 // console.log("top teams of week", topTeams);

  return (
    <div className="dashboard">
      <h1>Dashboard</h1>

      {/* Responsive Cards Section */}
      <div className="top-cards" style={{ margin: "0px auto" }}>
      {(userRole === "Agent" || userRole === "Frenchie") && (
  <>
    <div className="top-card">
      <h3>Top 3 Teams This Week</h3>
      {topTeams.length > 0 ? (
        topTeams.map((team, index) => {
          let rankIcon;
          // Conditionally assign rank icon based on index
          switch (index) {
            case 0:
              rankIcon = <MilitaryTech style={{ color: "#FFDE4D" }} />;
              break;
            case 1:
              rankIcon = <MilitaryTech style={{ color: "#d3d3d3" }} />;
              break;
            case 2:
              rankIcon = <MilitaryTech style={{ color: "#ce8946" }} />;
              break;
            default:
              rankIcon = null;
          }

          return (
            <p style={{ marginTop: "20px" }} key={index}>
              {rankIcon} {team.teamNo} : {team.count} Contract
            </p>
          );
        })
      ) : (
        <p>No data available</p>
      )}
    </div>

    <div className="top-card">
      <h3>Contract Status Summary</h3>
      <p>Approved: {contractCount.Approved}</p>
      <p>Confirmed: {contractCount.Confirmed}</p>
      <p>Booked: {contractCount.Booked}</p>
      <p>Quoted: {contractCount.Quoted}</p>
      <p>Cancelled: {contractCount.Cancelled}</p>
    </div>

    <div className="top-card">
      <h3>This Week</h3>
      <p>Total Contract: {allCount}</p>
    </div>
  </>
)}


        {userRole === "Admin" && (
          <>
            <div className="top-card">
              <h3>Balance</h3>
              {/* Placeholder for balance */}
              <p>Total Balance : {totalAmount}</p>
            </div>

            <div className="top-card">
              <h3>Top Branches Last Week</h3>
              <p>
                {topBranches.length > 0 ? (
                  <>
                    {topBranches.map(([branchId, count], index) => {
                      let rankIcon;
                      // Conditionally assign rank icon based on index
                      switch (index) {
                        case 0:
                          rankIcon = (
                            <MilitaryTech style={{ color: "#FFDE4D" }} />
                          );
                          break;
                        case 1:
                          rankIcon = (
                            <MilitaryTech style={{ color: "#d3d3d3" }} />
                          );
                          break;
                        case 2:
                          rankIcon = (
                            <MilitaryTech style={{ color: "#ce8946" }} />
                          );
                          break;
                        default:
                          rankIcon = null;
                      }

                      return (
                        <p key={index} style={{ marginTop: "20px" }}>
                          {rankIcon}
                          <strong style={{ marginLeft: "5px" }}>
                            Branch {branchId} :{" "}
                          </strong>{" "}
                          {count} Contracts
                        </p>
                      );
                    })}
                  </>
                ) : (
                  <span>No top branches available</span>
                )}
              </p>
            </div>

            <div className="top-card">
              <h3>Last Week</h3>
              {/* Placeholder for balance */}
              <p>Total Contracts : {allCount}</p>
            </div>

            <div className="top-card">
              <h3>Contract Status Summary</h3>
              <p>Approved: {contractCount.Approved}</p>
              <p>Confirmed: {contractCount.Confirmed}</p>
              <p>Booked: {contractCount.Booked}</p>
              <p>Quoted: {contractCount.Quoted}</p>
              <p>Cancelled: {contractCount.Cancelled}</p>
            </div>
          </>
        )}
      </div>

      {/* Main Dashboard Grid */}
      <div className="dashboard-grid-one">
        {/* Conditionally render components based on the user's role */}
        {(userRole === "Agent" || userRole === "Frenchie") && (
          <>
            <div className="dashboard-grid-item-one">
              <ContractStatus
                onContractCountUpdate={handleContractCountUpdate}
              />
            </div>

            <div className="dashboard-grid-item-one">
              <UserTotalContract />
            </div>

            <div className="dashboard-grid-item-one">
              <ContractPerTeam setTopTeams={setTopTeams} />
            </div>

            <div className="dashboard-grid-item-one">
              <TodayBarChart />
            </div>

            <div className="dashboard-grid-item-one">
              <ContractGraph setAllCount={setAllCount} />
            </div>

            {/* <div className="dashboard-grid-item-one">
      <TeamGraph />
    </div> */}
          </>
        )}

        {/* <div className="dashboard-grid-item-one">
              <TeamGraph />
            </div> */}

        {userRole === "Admin" && (
          <>
            <div className="dashboard-grid-item-one">
              <div className="component">
                <ContractStatus
                  onContractCountUpdate={handleContractCountUpdate}
                />
              </div>
            </div>

            <div className="dashboard-grid-item-one">
              <div className="component">
                <UserTotalContract />
              </div>
            </div>

            <div className="dashboard-grid-item-one">
              <div className="component">
                <AdminBranch setTopBranches={setTopBranches} />
              </div>
            </div>

            <div className="dashboard-grid-item-one">
              <div className="component">
                <BalanceLineChart setTotalAmout={setTotalAmount} />
              </div>
            </div>

            <div className="dashboard-grid-item-one">
              <div className="component">
                <ContractGraph setAllCount={setAllCount} />
              </div>
            </div>
          </>
        )}
      </div>

      {/* Add improved styles for the components */}
      <style>
        {`
          .dashboard {
            padding: 20px;
            font-family: Arial, sans-serif;
            background-color: #F4F7FF; 
            min-height: 100vh;
          }

          h1 {
            text-align: center;
            color: #333;
            margin-bottom: 30px;
            font-size: 2rem;
            font-weight: bold;
          }

          .top-cards {
            display: grid;
            width: 100%;  
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            gap: 20px;
            margin-bottom: 30px;
           
          }

          .top-card {
            background-color: #ffffff;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            height: 100%;
          }

          .top-card h3 {
            font-size: 1.5rem;
            font-weight: bold;
            color: #333;
            margin-bottom: 10px;
          }

          .top-card p {
            color: #555;
            font-size: 1.1rem;
          }

          .dashboard-grid {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
            gap: 20px; 
          }

          .dashboard-grid-one {
            display: grid;
            margin-top: 1rem;
            grid-template-columns: 1.5fr 2fr 1.5fr; /* First two columns equal, last one takes up double the space */
            height: 50%; /* Adjust height to 50% of the container */
            gap: 20px; 
          }

          .dashboard-grid-item-one {
            background-color: #ffffff;
            padding: 20px;
            border-radius: 10px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            overflow: hidden;
            max-width: 100%;
            height: 100%;
          }

          .component {
            margin: 0;
            padding: 0;
            height:'100%' !important;
            width: '100%';
            display: flex;
            flex-direction: column;
          }

          .component h3 {
            margin-bottom: 1.4rem;
            color: #333;
            font-weight: 700;
            font-size: 1.2rem;
            padding-bottom: 5px;
          }

          .component ul {
            list-style: none;
            padding: 0;
            margin: 0;
          }

          .component li {
            margin-bottom: 10px;
            font-size: 1rem;
            color: #555;
          }

          .component li::before {
            color: #75c3c9;
            font-weight: bold;
            display: inline-block;
            width: 1em;
            margin-left: -1em;
          }
        `}
      </style>
    </div>
  );
};

export default Dashboard;
